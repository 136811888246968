<template>
  <div class="map">
    <div id="map"></div>
    <map-settings v-if="map" :map="map"></map-settings>
  </div>
</template>

<style>
@import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';
</style>

<script>
import mapboxgl from 'mapbox-gl';
import { mapGetters, mapMutations } from 'vuex';
import config from '../helpers/configProvider';
import { ExtrusionControl } from '../helpers/map/ExtrusionControl';
import { FitBoundsControl } from '../helpers/map/FitBoundsControl';
import { MapSettingsControl } from '../helpers/map/MapSettingsControl';
import { SnapshotControl } from '../helpers/map/SnapshotControl';
import ApiService from '../services/ApiService';
import MapSettings from './MapSettings.vue';

let root =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  process.env.BASE_URL;

export default {
  name: 'Map',

  data() {
    return {
      map: undefined,
      MapSettings: undefined,
      extrusionControl: undefined,
      fitBoundsControl: undefined,
      snapshotControl: undefined,
      tileUrl:
        config.getEnv(config.enum.VUE_APP_TILE_URL) ||
        'https://vt-dev.mapcat.com/gli/hungary/tile/{z}/{x}/{y}.pbf'
    };
  },

  async mounted() {
    mapboxgl.accessToken = config.getEnv(config.enum.VUE_APP_MAPBOX_ACCESS_TOKEN);

    if (!mapboxgl.accessToken) {
      /* eslint-disable no-console */
      console.error('The Mapbox access token is not specified.');
    }

    let { data: mapcatJson } = await ApiService.getBaseMapStyle();

    // hide poi layers
    const poiLayerIds = ['poi-parks', 'poi-not-parks', 'poi-not-parks-label'];
    mapcatJson.layers
      .filter((layer) => poiLayerIds.includes(layer.id))
      .forEach((layer) => (layer.layout.visibility = 'none'));

    mapcatJson.sources['composite'].tiles = [this.tileUrl];
    mapcatJson.sources['composite.poi'].tiles = [this.tileUrl];
    mapcatJson.sources['composite.shape'].tiles = [this.tileUrl];
    mapcatJson.sources['mapbox.streets'] = {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2,mapbox.mapbox-bathymetry-v2'
    };
    mapcatJson.sources['mapbox.satellite'] = {
      type: 'raster',
      url: 'mapbox://mapbox.satellite',
      tileSize: 256
    };
    mapcatJson.sources['rooms'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    };
    mapcatJson.sources['zones'] = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    };
    mapcatJson.sprite = root + '/assets/sprite';

    this.map = new mapboxgl.Map({
      container: 'map',
      vector: true,
      zoom: 7,
      minZoom: 3,
      maxZoom: 26,

      center: [20.253, 47.267],
      style: mapcatJson,
      hash: true,
      attributionControl: true,
      zoomControl: true,
      dragRotate: true,
      preserveDrawingBuffer: true
    });

    let zoomControl = new mapboxgl.NavigationControl({
      position: 'bottom-right',
      visualizePitch: true
    });
    let scaleControl = new mapboxgl.ScaleControl({
      position: 'top-left',
      maxWidth: 100,
      unit: 'metric'
    });

    this.mapSettingsControl = new MapSettingsControl({
      title: this.$t('Map settings'),
      store: this.$store
    });
    this.extrusionControl = new ExtrusionControl({
      title3d: this.$t('Turn on 3D'),
      title2d: this.$t('Turn off 3D')
    });
    this.fitBoundsControl = new FitBoundsControl({ title: this.$t('Zoom to site') });
    this.snapshotControl = new SnapshotControl({ title: this.$t('Save map as image') });
    this.map.addControl(zoomControl, 'top-left');
    this.map.addControl(scaleControl);
    this.map.addControl(this.mapSettingsControl, 'top-left');
    this.map.addControl(this.extrusionControl, 'top-left');
    this.map.addControl(this.fitBoundsControl, 'top-left');
    this.map.addControl(this.snapshotControl, 'top-left');

    this.map.on('load', () => {
      this.map.addLayer({
        id: 'mapbox-satellite',
        type: 'raster',
        source: 'mapbox.satellite',
        layout: {
          visibility: 'none'
        },
        paint: {}
      });

      this.map.addLayer({
        id: 'zones',
        type: 'fill',
        source: 'zones',
        layout: {
          visibility: 'none'
        },
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': ['get', 'opacity']
        }
      });

      this.map.addLayer({
        id: 'zones-name',
        type: 'symbol',
        source: 'zones',
        layout: {
          visibility: 'none',
          'symbol-placement': 'point',
          'text-field': '{displayName}',
          'text-padding': 2,
          'text-anchor': 'center',
          'text-ignore-placement': false,
          'text-allow-overlap': false,
          'text-justify': 'center',
          'text-max-width': 7,
          'text-size': {
            stops: [
              [15, 10],
              [18, 16]
            ]
          }
        },
        paint: {
          'text-opacity': 0.65
        }
      });

      this.map.addLayer({
        id: 'rooms',
        type: 'fill',
        source: 'rooms',
        layout: {
          visibility: 'none'
        },
        paint: {
          'fill-color': '#a44a3f',
          'fill-opacity': 0.3
        }
      });

      this.setLastBaseMapLayerId('rooms');
      this.$emit('load', this.map);
    });

    this.localize();
  },

  computed: {
    ...mapGetters('status', ['extrusion'])
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.localize();
      }
    },

    extrusion() {
      this.extrusionControl.updateButton();
    }
  },

  methods: {
    ...mapMutations('app', ['setLastBaseMapLayerId']),
    localize() {
      document.querySelector('button.mapboxgl-ctrl-zoom-in').title = this.$t('Zoom in');
      document.querySelector('button.mapboxgl-ctrl-zoom-out').title = this.$t('Zoom out');
      document.querySelector('button.mapboxgl-ctrl-compass').title = this.$t('Compass');
      this.mapSettingsControl.localize({
        title: this.$t('Map settings')
      });
      this.extrusionControl.localize({
        title3d: this.$t('Turn on 3D'),
        title2d: this.$t('Turn off 3D')
      });
      this.fitBoundsControl.localize({ title: this.$t('Zoom to site') });
      this.snapshotControl.localize({ title: this.$t('Save map as image') });
    }
  },
  components: {
    MapSettings
  }
};
</script>
